@import "reset";
@import "colors";
@import "@photo-sphere-viewer/core/index.css";
@import "@photo-sphere-viewer/compass-plugin/index.css";


.app {
  // background-color:green;
  height: 100vh;
  width: 100vw;
}


.toolbar-module {
  width: 100%;
  height: 50%;
}

.main {
  height: calc(100vh - 64px);

  .toolbar-section {
    overflow-y: scroll;
    height: 50%;
  }

  .sidebar-left-top {
    background-color: #fff;
  }

  .sidebar-left-bottom {
    background-color: green;
  }

  .sidebar-right-top {}

  .sidebar-right-bottom {}


  .toolbar-left {
    background-color: purple;
    position: relative;
  }

  .stage {
    background-color: #000;
    height: calc(100vh - 64px);
    z-index: 0;
  }

  .toolbar-right {
    background-color: #fff;
    margin-top: 64px;
    overflow: scroll;
    height: 100vh;
  }
}

.stage {
  position: relative;
}



.app-header {
  z-index: 1;
  width: 100%;
  position: fixed;
  background-color: $cyan;
  height: 48px;
  display: flex;
  line-height: 48px;

  .nav-links {
    position: absolute;
    right: 8px;

    a {
      margin: 10px;
      color: #fff;
    }
  }

  .small-logo {
    max-height: 40px;
    max-width: 40px;
  }
}


.small-logo {
  max-height: 40px;
  max-width: 40px;
}



.map-container {
  position: relative;
  height: 100%;
  width: 100%;

}

.close-pano {
  position: absolute;
  z-index: 3;
  background-color: black;
  color: #fff;
  padding-top: 4px;
  padding-right: 3px;
  padding-left: 3px;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
    opacity: .9;
  }
}

.loading-wrap {
  background-color: #000;
  position: absolute;
  top: 20%;
  left: 45%;
  z-index: 3;
}

.pano-stage {
  z-index: 1;
}

.pano-stage,
.pano-wrap {
  height: 100%;
  width: 100%;
  position: relative;
}

.side-map-container {
  width: 100%;
  height: 300px;
}

.open-point-cloud {

  svg {
    width: 40px;
    height: 40px;
    position: relative;
    top: 15px;

    &:hover {
      color: #000;
    }
  }

  margin-top:10px;
  position:relative;
  cursor:pointer;
}


.map-app-wrap {
  width: 100%;
  height: 100%;
}

.map-control-layer-one {
  a {
    cursor: pointer;
  }

  background-color:#fff;
  position:absolute;
  bottom:20px;
  left:20px;
  padding:10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

}


.potree-wrapper {
  background-color: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

#potree-root {
  height: 100%;
}


.json-output {
  background-color: #000;
  color: #fff;
  padding: 20px;

  li {
    font-size: 12px;
    margin: 20px;
    padding: 10px;
    background-color: #282828;
  }
}

.project-list {
  position: relative;

  ul {
    padding-top: 20px;
  }

}

.active-project {
  background-color: #f1f1f1;
  padding: 10px;
  position: fixed;
  z-index: 3;
  top: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* This separates items at opposite ends */
  align-items: center;
  /* This vertically centers items */

  button {
    margin-left: 20px;
  }
}

.pole-management-wrap {
  padding-top: 5px;
  height: auto;
  position: relative;
  z-index: 0;

  .pole-base {
    background-color: #000;
    color: #fff;
    padding: 10px;
  }
}

.new-pole-button {
  position: fixed;
  top: -4px;
  right: 20px;
  z-index: 1;
}

.measurement-unit {
  margin-bottom: 20px;
  width: 100%;
  justify-content: flex-start;
  background-color: #f1f1f1;
  display: flex;
  padding: 15px;

  .measurement-chip {
    margin-left: 20px;
  }

}

.map-one-wrap {
  width: 100%;
}

.MuiTabs-root {
  button {
    &:hover {
      background-color: #f1f1f1;
    }
  }
}


.measure-area {
  display: flex;
  background-color: #f1f1f1;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;

  p {
    margin-right: 10px;
  }
}

.attachment-list-item {
  background-color: #f1f1f1;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}


.measurements-display-section {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 20px;
  margin-right: 20px;
  font-size: 13px;
  margin-bottom: 20px;

  .attachment-list-item {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
  }
}

.close-panel-button {
  position: fixed;
  top: 50px;
  right: 20px;
}

.attachment-manager-wrap {

  td,
  th {
    font-size: 10px;
  }
}


.save-measurements {
  position: fixed;
  right: 20px;
  top: 29px;
  background-color: #fff;
  z-index: 3;

}

.save-space {
  margin-right: 10px;
}


.sidebar-overflow-wrap {
  height: calc(100vh - 65px);
  overflow: auto;
  width: 100%;

}

.sidebar-module-wrap {
  width: 100%;
  overflow: auto;
}

@keyframes highlight {
  from {
    background-color: yellow;
  }
}